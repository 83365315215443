export default [
    {
        path: '',
        component: () => import('@/layouts/Main.vue'),
        children: [
          {
            path: '/categorias',
            name: 'categorias',
            component: () => import('@/views/category/pages/Categorys.vue'),
            meta: {requiresAuth: true}
          },
          {
            path: '/categoria',
            name: 'categoria',
            component: () => import('@/views/category/pages/Insert.vue'),
            meta: {requiresAuth: true}
          },
          {
            path: '/categoria/:id',
            name: 'categoria-edit',
            component: () => import('@/views/category/pages/Edit.vue'),
            meta: {requiresAuth: true}
          },
        ],
    },
  ]