import services from '@/http'

export const ActionPageCreate = (_, payload) => {
  return services.page.create(payload);
}

export const ActionPageAll = (_, payload) => {
  return services.page.all(payload);
}

export const ActionPageUpdate = (_, payload) => {
  return services.page.update({id: payload.id}, payload.data);
}

export const ActionPageFind = (_, payload) => {
  return services.page.find({id: payload});
}

export const ActionPageDeleteImage = (_, payload) => {
  return services.page.deleteImage({id: payload});
}

export const ActionPageUpdateImage = (_, payload) => {
  return services.page.updateImage({id: payload.id}, payload.data);
}