export default [
    {
        path: '',
        component: () => import('@/layouts/Main.vue'),
        children: [
          {
            path: '/aparencia',
            name: 'aparencia',
            component: () => import('@/views/appearance/pages/Appearance.vue'),
            meta: {requiresAuth: true}
          },
        ],
    },
  ]