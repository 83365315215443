export default [
    {
        path: '',
        component: () => import('@/layouts/Main.vue'),
        children: [
          {
            path: '/',
            name: 'home',
            component: () => import('@/views/home/pages/Home.vue'),
            meta: {requiresAuth: true}
          },
         
        ],
    },
  ]