import services from '@/http'

export const ActionBrandCreate = (_, payload) => {
  return services.brand.create(payload);
}

export const ActionBrandAll = (_, payload) => {
  return services.brand.all(payload);
}

export const ActionBrandUpdate = (_, payload) => {
  return services.brand.update({id: payload.id}, payload.data);
}

export const ActionBrandFind = (_, payload) => {
  return services.brand.find({id: payload});
}

export const ActionBrandDeleteImage = (_, payload) => {
  return services.brand.deleteImage({id: payload});
}