import services from '@/http'

export const ActionOptionCreate = (_, payload) => {
  return services.option.create(payload);
}

export const ActionOptionAll = (_, payload) => {
  return services.option.all(payload);
}

export const ActionOptionUpdate = (_, payload) => {
  return services.option.update({id: payload.id}, payload.data);
}

export const ActionOptionFind = (_, payload) => {
  return services.option.find({id: payload});
}