import services from '@/http'

export const ActionBannerCreate = (_, payload) => {
  return services.banner.create(payload)
}

export const ActionBannerAll = (_, payload) => {
  return services.banner.all(payload)
}

export const ActionBannerUpdate = (_, payload) => {
  return services.banner.update({ id: payload.id }, payload.data)
}

export const ActionBannerFind = (_, payload) => {
  return services.banner.find({ id: payload })
}

export const ActionBannerDeleteImage = (_, payload) => {
  return services.banner.deleteImage({ id: payload })
}

export const ActionBannerUpdateImage = (_, payload) => {
  return services.banner.updateImage({ id: payload.id }, payload.data)
}
