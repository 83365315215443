import services from '@/http'

export const ActionRatingAll = (_, payload) => {
  return services.rating.all(payload);
}

export const ActionRatingUpdate = (_, payload) => {
  return services.rating.update({id: payload.id}, payload.data);
}

export const ActionRatingFind = (_, payload) => {
  return services.rating.find({id: payload});
}
