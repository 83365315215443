import services from '@/http'

export const ActionCategoryBlogCreate = (_, payload) => {
  return services.blog.blogcreate(payload);
}

export const ActionCategoryBlogAll = (_, payload) => {
  return services.blog.blogall(payload);
}

export const ActionCategoryBlogUpdate = (_, payload) => {
  return services.blog.blogupdate({id: payload.id}, payload.data);
}

export const ActionCategoryBlogFind = (_, payload) => {
  return services.blog.blogfind({id: payload});
}

export const ActionCategoryBlogUpdateImage = (_, payload) => {
  return services.blog.updateImage({id: payload.id}, payload.data);
}

export const ActionCategoryBlogDeleteImage = (_, payload) => {
  return services.blog.deleteImage({id: payload});
}

export const ActionPostBlogCreate = (_, payload) => {
  return services.blog.blogpostcreate(payload);
}

export const ActionPostBlogAll = (_, payload) => {
  return services.blog.blogpostall(payload);
}

export const ActionPostBlogUpdate = (_, payload) => {
  return services.blog.blogpostupdate({id: payload.id}, payload.data);
}

export const ActionPostBlogFind = (_, payload) => {
  return services.blog.blogpostfind({id: payload});
}