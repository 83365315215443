export default [
    {
        path: '',
        component: () => import('@/layouts/Main.vue'),
        children: [
          {
            path: '/opcoes',
            name: 'opcoes',
            component: () => import('@/views/option/pages/Options.vue'),
            meta: {requiresAuth: true}
          },
          {
            path: '/opcao',
            name: 'opcao',
            component: () => import('@/views/option/pages/Insert.vue'),
            meta: {requiresAuth: true}
          },
          {
            path: '/opcao/:id',
            name: 'opcao-edit',
            component: () => import('@/views/option/pages/Edit.vue'),
            meta: {requiresAuth: true}
          },
        ],
    },
  ]