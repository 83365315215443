export default {
    create: { method: 'post', url: 'sale-admin/' },
    all: { method: 'get', url: 'sale-admin/' },
    update: { method: 'put', url: 'sale-admin{/id}' },
    find: { method: 'get', url: 'sale-admin{/id}' },
    updateStatus: { method: 'put', url: 'sale-status{/id}' },
    updateProduct: { method: 'put', url: 'sale-product{/id}' },
    updateFrete: { method: 'put', url: 'sale-changer-frete{/id}' },
    freteSale: { method: 'post', url: 'sale-frete/sales' },
    
  }