export default [
    {
        path: '',
        component: () => import('@/layouts/Main.vue'),
        children: [
          {
            path: '/configuracoes',
            name: 'configuracoes',
            component: () => import('@/views/setting/pages/Settings.vue'),
            meta: {requiresAuth: true}
          },
        ],
    },
  ]