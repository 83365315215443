export default [
    {
        path: '',
        component: () => import('@/layouts/FullPage.vue'),
        children: [
          {
            path: '/login',
            name: 'login',
            component: () => import('@/views/auth/pages/Login.vue'),
            meta: {requiresAuth: true}
          },
          {
            path: '/recover',
            name: 'recover',
            component: () => import('@/views/auth/pages/Recover.vue'),
            meta: {requiresAuth: true}
          },
          {
            path: '/recover/:id',
            name: 'recover-code',
            component: () => import('@/views/auth/pages/RecoverCode.vue'),
            meta: {requiresAuth: true}
          },
        ],
    },
  ]