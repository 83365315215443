export default [
    {
        path: '',
        component: () => import('@/layouts/Main.vue'),
        children: [
          {
            path: '/fabricantes',
            name: 'fabricantes',
            component: () => import('@/views/manufacturer/pages/Manufacturers.vue'),
            meta: {requiresAuth: true}
          },
          {
            path: '/fabricante',
            name: 'fabricante',
            component: () => import('@/views/manufacturer/pages/Insert.vue'),
            meta: {requiresAuth: true}
          },
          {
            path: '/fabricante/:id',
            name: 'fabricante-edit',
            component: () => import('@/views/manufacturer/pages/Edit.vue'),
            meta: {requiresAuth: true}
          },
        ],
    },
  ]