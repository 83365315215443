export default [
    {
        path: '',
        component: () => import('@/layouts/Main.vue'),
        children: [
          {
            path: '/clientes',
            name: 'clientes',
            component: () => import('@/views/customer/pages/Customers.vue'),
            meta: {requiresAuth: true}
          },
          {
            path: '/cliente',
            name: 'cliente',
            component: () => import('@/views/customer/pages/Insert.vue'),
            meta: {requiresAuth: true}
          },
          {
            path: '/cliente/:id',
            name: 'detalhe-cliente',
            component: () => import('@/views/customer/pages/Views.vue'),
            meta: {requiresAuth: true}
          },
          {
            path: '/cliente-edit/:id',
            name: 'editar-cliente',
            component: () => import('@/views/customer/pages/Edit.vue'),
            meta: {requiresAuth: true}
          },
        ],
    },
  ]