export default [
    {
        path: '',
        component: () => import('@/layouts/Main.vue'),
        children: [
          {
            path: '/banners',
            name: 'banners',
            component: () => import('@/views/banner/pages/Banners.vue'),
            meta: {requiresAuth: true}
          },
          {
            path: '/banner',
            name: 'banner',
            component: () => import('@/views/banner/pages/Insert.vue'),
            meta: {requiresAuth: true}
          },
          {
            path: '/banner/:id',
            name: 'banner-edit',
            component: () => import('@/views/banner/pages/Edit.vue'),
            meta: {requiresAuth: true}
          },
        ],
    },
  ]