import services from '@/http'

export const ActionContactAll = (_, payload) => {
  return services.contact.all(payload);
}

export const ActionContactUpdate = (_, payload) => {
  return services.contact.update(payload.data);
}

export const ActionContactFind = (_, payload) => {
  return services.contact.find({id: payload});
}

export const ActionContactDelete = (_, payload) => {
  return services.contact.delete({id: payload});
}