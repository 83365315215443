import { routes as home } from "../views/home";
import { routes as category } from "../views/category";
import { routes as product } from "../views/product";
import { routes as page } from "../views/page";
import { routes as manufacturer } from "../views/manufacturer";
import { routes as brand } from "../views/brand";
import { routes as rating } from "../views/rating";
import { routes as sale } from "../views/sale";
import { routes as customer } from "../views/customer";
import { routes as finance } from "../views/finance";
import { routes as email } from "../views/email";
import { routes as marketing } from "../views/marketing";
import { routes as testimony } from "../views/testimony";
import { routes as blog } from "../views/blog";
import { routes as appearance } from "../views/appearance";
import { routes as contact } from "../views/contact";
import { routes as newsletter } from "../views/newsletter";
import { routes as setting } from "../views/setting";
import { routes as auth } from "../views/auth";
import { routes as profile } from "../views/profile";
import { routes as banner } from "../views/banner";
import { routes as option } from "../views/option";

export default [
  ...home,
  ...category,
  ...product,
  ...page,
  ...manufacturer,
  ...brand,
  ...rating,
  ...sale,
  ...customer,
  ...finance,
  ...email,
  ...marketing,
  ...testimony,
  ...blog,
  ...appearance,
  ...contact,
  ...newsletter,
  ...setting,
  ...auth,
  ...profile,
  ...banner,
  ...option,
];
