export default [
    {
        path: '',
        component: () => import('@/layouts/Main.vue'),
        children: [
          {
            path: '/newsletters',
            name: 'newsletters',
            component: () => import('@/views/newsletter/pages/Newsletters.vue'),
            meta: {requiresAuth: true}
          },
        ],
    },
  ]