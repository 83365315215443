import services from '@/http'

export const ActionEmailCreate = (_, payload) => {
  return services.email.create(payload);
}

export const ActionEmailAll = (_, payload) => {
  return services.email.all(payload);
}

export const ActionEmailUpdate = (_, payload) => {
  return services.email.update(payload);
}

export const ActionEmailDelete = (_, payload) => {
  return services.email.delete(payload);
}