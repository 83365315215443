import services from '@/http'

export const ActionSaleAll = (_, payload) => {
  return services.sale.all(payload);
}

export const ActionSaleUpdate = (_, payload) => {
  return services.sale.update({id: payload.id}, payload.data);
}

export const ActionSaleFind = (_, payload) => {
  return services.sale.find({id: payload});
}

export const ActionSaleStatusUpdate = (_, payload) => {
  return services.sale.updateStatus({id: payload.id}, payload.data);
}

export const ActionSaleProductUpdate = (_, payload) => {
  return services.sale.updateProduct({id: payload.id}, payload.data);
}

export const ActionFreteSaleAll = (_, payload) => {
  return services.sale.freteSale(payload);
}

export const ActionSaleFreteUpdate = (_, payload) => {
  return services.sale.updateFrete({id: payload.id}, payload.data);
}

