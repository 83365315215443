export default [
    {
        path: '',
        component: () => import('@/layouts/Main.vue'),
        children: [
          {
            path: '/contatos',
            name: 'contatos',
            component: () => import('@/views/contact/pages/Contacts.vue'),
            meta: {requiresAuth: true}
          },
          {
            path: '/contato/:id',
            name: 'contato',
            component: () => import('@/views/contact/pages/Contact.vue'),
            meta: {requiresAuth: true}
          },
        ],
    },
  ]