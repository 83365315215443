import services from '@/http'

export const ActionMenuAll = (_, payload) => {
  return services.appearance.allMenu(payload);
}

export const ActionMenuCreate = (_, payload) => {
  return services.appearance.createdMenu(payload);
}

export const ActionFeaturedCreate = (_, payload) => {
  return services.appearance.createdFeatured(payload);
}

export const ActionFeaturedAll = (_, payload) => {
  return services.appearance.allFeatured(payload);
}

export const ActionFeaturedUpdate = (_, payload) => {
  return services.appearance.updateFeatured(payload);
}

export const ActionFeaturedDelete = (_, payload) => {
  return services.appearance.deleteFeatured({id: payload.id}, payload.data);
}


export const ActionFeaturedCategoryCreate = (_, payload) => {
  return services.appearance.createdFeaturedCategory(payload);
}

export const ActionFeaturedCategoryAll = (_, payload) => {
  return services.appearance.allFeaturedCategory(payload);
}

export const ActionFeaturedCategoryUpdate = (_, payload) => {
  return services.appearance.updateFeaturedCategory(payload);
}

export const ActionFeaturedCategoryDelete = (_, payload) => {
  return services.appearance.deleteFeaturedCategory({id: payload.id}, payload.data);
}

export const ActionSliderCreate = (_, payload) => {
  return services.appearance.createdSlider(payload);
}

export const ActionSliderAll = (_, payload) => {
  return services.appearance.allSlider(payload);
}

export const ActionSliderUpdate = (_, payload) => {
  return services.appearance.updateSlider(payload);
}

export const ActionSliderDelete = (_, payload) => {
  return services.appearance.deleteSlider({id: payload.id}, payload.data);
}

export const ActionInfoFind = (_, payload) => {
  return services.appearance.findInfo(payload);
}

export const ActionInfoUpdate = (_, payload) => {
  return services.appearance.updateInfo(payload);
}

export const ActionMenuDelete = (_, payload) => {
  return services.appearance.deleteMenu({id: payload.id},payload.data);
}

export const ActionAppearanceUpdate = (_, payload) => {
  return services.appearance.update(payload);
}

export const ActionAppearanceUpdateSession = (_, payload) => {
  return services.appearance.updateSession(payload);
}

export const ActionAppearanceFind = (_, payload) => {
  return services.appearance.find(payload);
}

export const ActionAppearanceUpdateImage = (_, payload) => {
  return services.appearance.updateImage({id: payload.id}, payload.data);
}

export const ActionAppearanceDeleteImage = (_, payload) => {
  return services.appearance.deleteImage({id: payload});
}

export const ActionCountdownCreate = (_, payload) => {
  return services.appearance.createdCountdown(payload);
}

export const ActionCountdownAll = (_, payload) => {
  return services.appearance.allCountdown(payload);
}

export const ActionCountdownUpdate = (_, payload) => {
  return services.appearance.updateCountdown(payload);
}

export const ActionCountdownDelete = (_, payload) => {
  return services.appearance.deleteCountdown({id: payload.id}, payload.data);
}