export default {
    blogcreate: { method: 'post', url: 'blog-category/' },
    blogall: { method: 'get', url: 'blog-category-all/' },
    blogupdate: { method: 'put', url: 'blog-category{/id}' },
    blogfind: { method: 'get', url: 'blog-category{/id}' },
    updateImage: { method: 'put', url: 'image{/id}' },
    deleteImage: { method: 'delete', url: 'image{/id}' },
    blogpostcreate: { method: 'post', url: 'blog-post/' },
    blogpostall: { method: 'get', url: 'blog-post-all/' },
    blogpostupdate: { method: 'put', url: 'blog-post{/id}' },
    blogpostfind: { method: 'get', url: 'blog-post{/id}' },
  }