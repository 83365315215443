export default [
    {
        path: '',
        component: () => import('@/layouts/Main.vue'),
        children: [
          {
            path: '/profile',
            name: 'profile',
            component: () => import('@/views/profile/pages/Profile.vue'),
            meta: {requiresAuth: true}
          },
        ],
    },
  ]