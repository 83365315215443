import services from '@/http'

export const ActionProductCreate = (_, payload) => {
  return services.product.create(payload);
}

export const ActionProductAll = (_, payload) => {
  return services.product.all(payload);
}

export const ActionProductUpdate = (_, payload) => {
  return services.product.update({id: payload.id}, payload.data);
}

export const ActionProductFind = (_, payload) => {
  return services.product.find({id: payload});
}

export const ActionProductDeleteImage = (_, payload) => {
  return services.product.deleteImage({id: payload});
}

export const ActionProductUpdateImage = (_, payload) => {
  return services.product.updateImage({id: payload.id}, payload.data);
}

export const ActionProductDeleteFile = (_, payload) => {
  return services.product.deleteFile({id: payload});
}

export const ActionProductUpdateFile = (_, payload) => {
  return services.product.updateFile({id: payload.id}, payload.data);
}