import services from '@/http'

export const ActionManufacturerCreate = (_, payload) => {
  return services.manufacturer.create(payload);
}

export const ActionManufacturerAll = (_, payload) => {
  return services.manufacturer.all(payload);
}

export const ActionManufacturerUpdate = (_, payload) => {
  return services.manufacturer.update({id: payload.id}, payload.data);
}

export const ActionManufacturerFind = (_, payload) => {
  return services.manufacturer.find({id: payload});
}

export const ActionManufacturerDeleteImage = (_, payload) => {
  return services.manufacturer.deleteImage({id: payload});
}