import services from '@/http'

export const ActionAccountCreate = (_, payload) => {
  return services.finance.create(payload);
}

export const ActionAccountAll = (_, payload) => {
  return services.finance.all(payload);
}

export const ActionAccountUpdate = (_, payload) => {
  return services.finance.update({id: payload.id}, payload.data);
}

export const ActionAccountFind = (_, payload) => {
  return services.finance.find({id: payload});
}

export const ActionTransactionAll = (_, payload) => {
  return services.finance.allTransaction(payload);
}

export const ActionTransactionSaldo = (_, payload) => {
  return services.finance.saldo(payload);
}

export const ActionTransictionCreate = (_, payload) => {
  return services.finance.createTransiction(payload);
}
