import services from '@/http'

export const ActionCategoryCreate = (_, payload) => {
  return services.category.create(payload);
}

export const ActionCategoryAll = (_, payload) => {
  return services.category.all(payload);
}

export const ActionCategoryUpdate = (_, payload) => {
  return services.category.update({id: payload.id}, payload.data);
}

export const ActionCategoryFind = (_, payload) => {
  return services.category.find({id: payload});
}

export const ActionCategoryUpdateImage = (_, payload) => {
  return services.category.updateImage({id: payload.id}, payload.data);
}

export const ActionCategoryDeleteImage = (_, payload) => {
  return services.category.deleteImage({id: payload});
}