import services from '@/http'

export const ActionCustomerCreate = (_, payload) => {
  return services.customer.create(payload);
}

export const ActionCustomerAll = (_, payload) => {
  return services.customer.all(payload);
}

export const ActionCustomerUpdate = (_, payload) => {
  return services.customer.update({id: payload.id}, payload.data);
}

export const ActionCustomerFind = (_, payload) => {
  return services.customer.find({id: payload});
}

export const ActionCustomerDeleteImage = (_, payload) => {
  return services.customer.deleteImage({id: payload});
}