export default [
    {
        path: '',
        component: () => import('@/layouts/Main.vue'),
        children: [
          {
            path: '/produtos',
            name: 'produtos',
            component: () => import('@/views/product/pages/Products.vue'),
            meta: {requiresAuth: true}
          },
          {
            path: '/produto',
            name: 'produto',
            component: () => import('@/views/product/pages/Insert.vue'),
            meta: {requiresAuth: true}
          },
          {
            path: '/detalhes-do-produto/:id',
            name: 'detalhes-do-produto',
            component: () => import('@/views/product/pages/Views.vue'),
            meta: {requiresAuth: true}
          },
          {
            path: '/produto/:id',
            name: 'produto-edit',
            component: () => import('@/views/product/pages/Edit.vue'),
            meta: {requiresAuth: true}
          },
        ],
    },
  ]