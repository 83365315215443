export default [
    {
        path: '',
        component: () => import('@/layouts/Main.vue'),
        children: [
          {
            path: '/blog-categorias',
            name: 'blog-categorias',
            component: () => import('@/views/blog/pages/Categorys.vue'),
            meta: {requiresAuth: true}
          },
          {
            path: '/blog-categoria',
            name: 'blog-categoria',
            component: () => import('@/views/blog/pages/InsertCategory.vue'),
            meta: {requiresAuth: true}
          },
          {
            path: '/blog-categoria/:id',
            name: 'blog-categoria-edit',
            component: () => import('@/views/blog/pages/EditCategory.vue'),
            meta: {requiresAuth: true}
          },
          {
            path: '/blog-posts',
            name: 'blog-posts',
            component: () => import('@/views/blog/pages/Posts.vue'),
            meta: {requiresAuth: true}
          },
          {
            path: '/blog-post',
            name: 'blog-post',
            component: () => import('@/views/blog/pages/InsertPost.vue'),
            meta: {requiresAuth: true}
          },
          {
            path: '/blog-post/:id',
            name: 'blog-post-edit',
            component: () => import('@/views/blog/pages/EditPost.vue'),
            meta: {requiresAuth: true}
          },
        ],
    },
  ]