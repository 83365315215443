import services from '@/http'

export const ActionTestimonyCreate = (_, payload) => {
  return services.testimony.create(payload);
}

export const ActionTestimonyAll = (_, payload) => {
  return services.testimony.all(payload);
}

export const ActionTestimonyUpdate = (_, payload) => {
  return services.testimony.update({id: payload.id}, payload.data);
}

export const ActionTestimonyUpdateImage = (_, payload) => {
  return services.testimony.updateImage({id: payload.id}, payload.data);
}

export const ActionTestimonyDeleteImage = (_, payload) => {
  return services.testimony.deleteImage({id: payload});
}