export default [
    {
        path: '',
        component: () => import('@/layouts/Main.vue'),
        children: [
          {
            path: '/paginas',
            name: 'paginas',
            component: () => import('@/views/page/pages/Pages.vue'),
            meta: {requiresAuth: true}
          },
          {
            path: '/pagina',
            name: 'pagina',
            component: () => import('@/views/page/pages/Insert.vue'),
            meta: {requiresAuth: true}
          },
          {
            path: '/page/:id',
            name: 'page-edit',
            component: () => import('@/views/page/pages/Edit.vue'),
            meta: {requiresAuth: true}
          },
        ],
    },
  ]