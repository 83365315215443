export default [
    {
        path: '',
        component: () => import('@/layouts/Main.vue'),
        children: [
          {
            path: '/marcas',
            name: 'marcas',
            component: () => import('@/views/brand/pages/Brands.vue'),
            meta: {requiresAuth: true}
          },
          {
            path: '/marca',
            name: 'marca',
            component: () => import('@/views/brand/pages/Insert.vue'),
            meta: {requiresAuth: true}
          },
          {
            path: '/marca/:id',
            name: 'marca-edit',
            component: () => import('@/views/brand/pages/Edit.vue'),
            meta: {requiresAuth: true}
          },
        ],
    },
  ]