export default [
    {
        path: '',
        component: () => import('@/layouts/Main.vue'),
        children: [
          {
            path: '/financeiro',
            name: 'financeiro',
            component: () => import('@/views/finance/pages/Finance.vue'),
            meta: {requiresAuth: true}
          },
          {
            path: '/contas-bancarias',
            name: 'contas-bancarias',
            component: () => import('@/views/finance/pages/Account.vue'),
            meta: {requiresAuth: true}
          },
        ],
    },
  ]