import services from '@/http'

export const ActionFreteFind = (_, payload) => {
  return services.setting.findFrete(payload);
}

export const ActionFreteUpdate = (_, payload) => {
  return services.setting.updateFrete(payload);
}

export const ActionNotificacaoFind = (_, payload) => {
  return services.setting.findNotificacao(payload);
}

export const ActionNotificacaoUpdate = (_, payload) => {
  return services.setting.updateNotificacao(payload);
}

export const ActionTempalteEmailAll = (_, payload) => {
  return services.setting.templateEmails(payload);
}

export const ActionTemplateEmailUpdate = (_, payload) => {
  return services.setting.templateEmailUpdate(payload);
}

export const ActionAlertAll = (_, payload) => {
  return services.setting.alert(payload);
}

export const ActionAlertUpdate = (_, payload) => {
  return services.setting.alertUpdate(payload);
}