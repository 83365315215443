export default [
    {
        path: '',
        component: () => import('@/layouts/Main.vue'),
        children: [
          {
            path: '/vendas',
            name: 'vendas',
            component: () => import('@/views/sale/pages/Sales.vue'),
            meta: {requiresAuth: true}
          },
          {
            path: '/venda/:id',
            name: 'venda',
            component: () => import('@/views/sale/pages/Sale.vue'),
            meta: {requiresAuth: true}
          },
          {
            path: '/detalhes-da-venda/:id',
            name: 'detalhes-da-venda',
            component: () => import('@/views/sale/pages/Views.vue'),
            meta: {requiresAuth: true}
          },
        ],
    },
  ]