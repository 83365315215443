export default [
    {
        path: '',
        component: () => import('@/layouts/Main.vue'),
        children: [
          {
            path: '/cupons',
            name: 'cupons',
            component: () => import('@/views/marketing/pages/Cupons.vue'),
            meta: {requiresAuth: true}
          },
        ],
    },
  ]